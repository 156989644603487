import React from "react"

const PocTable = () => {
  return (
    <div className="box" style={{ width: "100%" }}>
      <table className="table is-bordered is-fullwidth is-hoverable has-text-centered">
        <thead>
          <tr>
            <th className="is-link has-text-centered" colSpan={3}>
              Standard za kvalitet
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>EN 10142</td>
            <td>DIN 17162‐1</td>
            <td>JUS C.B4.025</td>
          </tr>
          <tr>
            <td className="is-link has-text-centered" colSpan={3}>
              Oznaka vrste čelika
            </td>
          </tr>
          <tr>
            <td>DX51D(FeP02GZ)</td>
            <td>St 02 Z</td>
            <td>Č.Zn02</td>
          </tr>
          <tr>
            <td>DX52D(FeP03GZ)</td>
            <td>St 03 Z</td>
            <td>Č.Zn03</td>
          </tr>
          <tr>
            <td>DX53D(FeP05GZ)</td>
            <td>St 05 Z</td>
            <td>Č.Zn04</td>
          </tr>
          <tr>
            <td>DX54D(FeP06GZ)</td>
            <td>St 06 Z</td>
            <td>Č.Zn05</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default PocTable
