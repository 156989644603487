import React from "react"
import ProductsLayout from "../../components/productsLayout"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import ImageGallery from "react-image-gallery"
import KotlovskiTable from "../../components/tables/KotlovskiTable"
import Img1 from "../../images/POCTABLE.jpg"
import Img2 from "../../images/pctrake.jpg"
import Img3 from "../../images/pckotur.jpg"
import PocTable from "../../components/tables/PocinkovaniTable"
import Head from "../../components/head"

const pocinkovaniLimovi = () => {
  const title = "Pocinkovani limovi"
  const images = [
    {
      original: `${Img1}`,
      thumbnail: `${Img1}`,
    },
    {
      original: `${Img2}`,
      thumbnail: `${Img2}`,
    },
    {
      original: `${Img3}`,
      thumbnail: `${Img3}`,
    },
  ]
  return (
    <Layout>
      <Head title={title} />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">{title}</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li>
                        <Link to="/proizvodi">Proizvodi</Link>
                      </li>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          {title}
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div className="columns">
            <div className="column">
              <ImageGallery
                additionalClass="box"
                infinite={false}
                showNav={false}
                thumbnailPosition="bottom"
                lazyLoad={true}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                originalClass="main-img"
              />
            </div>
            <div className="column">
              <p>
                <strong>TOPLOPONCIKOVANI LIMOVI</strong> od niskougljeničnih
                čelika imaju široku primenu u raznim industrijskim granama, gde
                je otpornost prema koroziji najbitnija.
              </p>
              <br />
              <p>
                <strong>Asortiman:</strong>
                <br />
                Pocinkovani limovi isporučuju se kao:
              </p>
              <div className="columns is-multiline">
                <div className="column is-full">
                  <ul>
                    <li>
                      <b>Slitovane trake</b>
                    </li>
                    <li>debljine od 0,40 - 6,00 mm</li>
                    <li>širine od 30 - 1600 mm</li>
                  </ul>
                </div>
                <div className="column ">
                  <ul>
                    <li>
                      <b>Table</b>
                    </li>
                    <li>debljine od 0,40 - 6,00 mm</li>
                    <li>širine od 800 - 1500 mm</li>
                    <li>dužine od 1000 - 3000 mm</li>
                  </ul>
                </div>
                <div className="column is-full">
                  <ul>
                    <li>
                      <b>Rolne</b>
                    </li>
                    <li>debljine od 0,40 - 6,00 mm</li>
                    <li>širine od 1000 - 1500 mm</li>
                  </ul>
                </div>
                <div className="column is-full">
                  <ul>
                    <strong>Dozvoljena odstupanja:</strong>
                    <p>
                      Propisuje standard za oblik, mere i dozvoljena odstupanja
                      SRPS EN 10143
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <section className="tab-section">
            <div class="tabs">
              <ul>
                <li className="is-active is-size-3">
                  <a>Hemijski sastav</a>
                </li>
              </ul>
            </div>
            <PocTable />
          </section>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default pocinkovaniLimovi
