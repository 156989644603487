import React from "react"

const KotlovskiTable = () => {
  return (
    <div className="box" style={{ width: "100%" }}>
      <table className="table is-fullwidth is-bordered is-hoverable">
        <thead>
          <tr>
            <th className="is-link has-text-centered" colSpan={3}>
              Standard za kvalitet
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>EN 10027</td>
            <td>DIN 17155</td>
            <td>JUS C.B4.014</td>
          </tr>
          <tr>
            <td className="is-link has-text-centered" colSpan={3}>
              Oznaka vrste čelika
            </td>
          </tr>
          <tr>
            <td>P 235 GH</td>
            <td>H I</td>
            <td>Č 1202</td>
          </tr>
          <tr>
            <td>P 265 GH</td>
            <td>H II</td>
            <td>Č 1204</td>
          </tr>
          <tr>
            <td>P 295 GH</td>
            <td>17Mn 4</td>
            <td>Č 3133</td>
          </tr>
          <tr>
            <td>P 255 GH</td>
            <td>19Mn 6</td>
            <td>/</td>
          </tr>
          <tr>
            <td>16Mo 3</td>
            <td>15Mo 3</td>
            <td>Č 7100</td>
          </tr>
          <tr>
            <td>13 CrMo 4‐5</td>
            <td>13 CrMo 44</td>
            <td>Č 7400</td>
          </tr>
          <tr>
            <td>10 CrMo 9‐10</td>
            <td>10 CrMo 9 10</td>
            <td>Č 7401</td>
          </tr>
          <tr>
            <td>11 CrMo 9‐10</td>
            <td>/</td>
            <td>/</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default KotlovskiTable
